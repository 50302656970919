<template>
	<v-row
        justify="center"
        class="mt-8"
    >
        <v-col
            cols="12"
            md="4"
            xl="4"
            sm="6"
        >
            <v-card>
                <v-card-title
                    class="indigo white--text"
                >
                    <h3>Recuperar Senha</h3>
                </v-card-title>
                <v-card-text class="pt-4" >
                    <v-form>
                        <v-text-field
                            v-model="form.email"
                            label="Email"
                            type="email"
                            maxlength="64"
							:rules="[
								form_email_rules.required, form_email_rules.email,
									form_email_rules.counter
							]"
                        ></v-text-field>
					</v-form>
				</v-card-text>
				<v-card-actions>
                    <v-btn
                        color="indigo"
                        dark
                        block
                    >
                        Enviar
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-btn
                        :to="{name: 'Login'}"
                        color="indigo"
                        dark
                        block
                        link
                    >
                        Voltar
                    </v-btn>
                </v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	data() {
		return {
			form: {
				email: ''
			},
			form_email_rules: {
				required: value => !!value || 'Email obrigatório.',
				counter: value => value.length <= 64 || 'Email inválido',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'E-mail inválido.'
				}
			}
		}
	}
}
</script>
